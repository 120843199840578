import { TYPE_FOLIO_SKUS } from 'src/data/productData';
import { UseCartAPI } from 'src/hooks/cart/useCart';
import { InfoBox } from './InfoBox';

const refurbishedMultipleDiscountDisclaimer = (
  <p data-cy="no-discounts-message">
    Refurbished devices <strong>does not trigger multiple discount.</strong>
  </p>
);

const radioLicenseDisclaimer = (
  <p>
    If purchasing multiple devices, <strong>you must have an import license</strong>, as defined by the Radio Waves Act.
    This also applies to orders being processed and shipped, so make sure that you only have one active order if you
    don't have an import license.
  </p>
);

const valueLimitDisclaimer = (
  <p>
    Please register with customs for clearance if the value of your order exceeds ฿40,000. Visit support.remarkable.com
    for more information.
  </p>
);

const poBoxDisclaimer = <p>Please note that we don’t deliver to PO boxes or addresses within free-trade zones (FZ).</p>;
const typeFolioDisclaimer = (
  <p>
    Make sure that you have the <strong>correct keyboard language</strong> for your Type Folio.
  </p>
);
const typeFolioDisclaimerText = 'Make sure that you have the correct keyboard language.';

const infoBoxDivider = (
  <div className="my-16 border border-b-0 border-l-0 border-r-0 border-solid border-grayscale-gray-100 broder-t-1"></div>
);

type Props = {
  country: string;
  deviceLimit: {
    limit: number;
    disclaimer: string | null;
  } | null;
  activeReferral: boolean | null;
  cart: UseCartAPI;
  lastSelectedPromotionBundle?: string;
};

const CartInfoBox: React.FC<Props> = ({
  cart,
  country,
  deviceLimit,
  lastSelectedPromotionBundle,
}): JSX.Element | null => {
  const refurbDeviceQuantity = cart.helpers.getNumberOfRerubishedDevicesInCart();
  const totalDeviceQuantity = cart.helpers.getNumberOfNewDevicesInCart() + refurbDeviceQuantity;

  const showTypeFolioDisclaimer = cart.data?.bundles.some((it) => {
    const isSelectedPromotionBundle = lastSelectedPromotionBundle === it.bundle;
    const hasTypeFolio = it.items.some((item) => TYPE_FOLIO_SKUS.some((sku) => sku === item.sku));

    return isSelectedPromotionBundle && hasTypeFolio;
  });

  // Construct array of applicable messages for given cart
  const infoBoxMessages = [
    deviceLimit && totalDeviceQuantity >= deviceLimit.limit ? deviceLimit.disclaimer : undefined,
    refurbDeviceQuantity > 1 ? refurbishedMultipleDiscountDisclaimer : undefined,
    country === 'KR' && totalDeviceQuantity > 1 ? radioLicenseDisclaimer : undefined,
    country === 'TH' ? valueLimitDisclaimer : undefined,
    country === 'AE' || country == 'QA' ? poBoxDisclaimer : undefined,
    showTypeFolioDisclaimer ? typeFolioDisclaimer : undefined,
  ].filter((msg) => msg !== undefined);

  if (infoBoxMessages.length === 0) {
    return null;
  }

  if (infoBoxMessages.length === 1 && showTypeFolioDisclaimer) {
    return <InfoBox heading={typeFolioDisclaimerText}></InfoBox>;
  }

  // Insert a divider in between each message, and remove the leading divider with slice
  const separatedMessages = infoBoxMessages.flatMap((msg) => [infoBoxDivider, msg]).slice(1);

  return <InfoBox heading="Please note" content={<>{separatedMessages}</>}></InfoBox>;
};

export default CartInfoBox;
