import { connectPPSku, connectSku } from 'src/helpers/connectHelpers';

import { useFormattedLocalizedPrice } from '../../../helpers/formattingHelpers';
import { PriceProps } from '../types';
import { useSkuPrices } from './useSkuPrices';

export type ConnectPrices = {
  connect: PriceProps;
  connectPP: PriceProps;
};

export type ConnectPricesRaw = {
  RM110: number;
  connect: number;
  connectPP: number;
};

export const useStaticConnectPricesRaw = (currency: string): ConnectPricesRaw => {
  const skuPrices = useSkuPrices(currency);

  const existingPrice = (sku: string) => {
    const price = skuPrices[sku];

    if (!price && sku !== connectPPSku) {
      throw Error(`Could not find price for ${sku} for currency: ${currency}`);
    }
    return price;
  };
  const checkedPrices = {
    RM110: existingPrice('RM110'),
    connect: existingPrice(connectSku),
    connectPP: existingPrice(connectPPSku),
  };
  return checkedPrices;
};

export const useStaticConnectPrices = (currency: string): ConnectPrices => {
  const checkedPrices = useStaticConnectPricesRaw(currency);

  const connectCurrentPrice = useFormattedLocalizedPrice(checkedPrices.connect);
  const connectPPCurrentPrice = useFormattedLocalizedPrice(checkedPrices.connectPP);

  return {
    connect: {
      current: connectCurrentPrice,
    },
    connectPP: {
      current: connectPPCurrentPrice,
    },
  };
};
