import { Button } from '@remarkable/ark-web';
import classNames from 'classnames';
import { X } from 'phosphor-react';
import React, { useEffect } from 'react';
import FocusLockUI from 'react-focus-lock/UI';
import { useHandleEscKey } from 'src/hooks/useHandleEscKey';
import { useLockScroll } from 'src/hooks/useLockScroll';
import { sidecar } from 'use-sidecar';

const FocusLockSidecar = sidecar(() => import(/* webpackPrefetch: true */ 'react-focus-lock/sidecar'));

type ExpandableModalProps = {
  show: boolean;
  handleClose: () => void;
  hideCross?: boolean;
  center?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const ExpandableModal: React.FunctionComponent<ExpandableModalProps> = ({
  show,
  handleClose,
  hideCross,
  children,
  center = false,
}) => {
  useLockScroll(show);

  /* Close drawer when Esc is pressed */
  useHandleEscKey(handleClose, show);

  useEffect(() => {
    document.body.classList.toggle('modal-open', show);
  }, [show]);

  return (
    <FocusLockUI sideCar={FocusLockSidecar} disabled>
      <div
        className={classNames('fixed top-0 bottom-0 left-0 right-0 z-[120] bg-black/50 overflow-y-scroll opacity-0', {
          hidden: !show,
          'flex items-center justify-center': center,
          'animate-fade-in': show,
        })}
        onClick={() => {
          handleClose();
        }}
      >
        <article
          className={classNames('bg-white md:max-w-[560px] max-w-full p-24 rounded-[0.5rem] m-24 md:mx-auto mb-20', {
            'md:mt-[160px] md:mb-[160px]': !center,
            'mt-[-40px]': center,
          })}
          role="dialog"
          aria-modal="true"
          data-open={show}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="flex justify-end">
            {!hideCross && (
              <Button
                variant="tertiary-neutral"
                size="medium"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                <X />
              </Button>
            )}
          </div>
          <div>{children}</div>
        </article>
      </div>
    </FocusLockUI>
  );
};

export default ExpandableModal;
