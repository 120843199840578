import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isAffirmSupportedCountry } from 'src/helpers/paymentHelpers';
import { State } from 'src/redux/reducers';
import * as ThirdPartyScripts from 'src/scripts/thirdPartyScripts';
import { updateAffirmWidget } from 'src/services/affirmApi';

import { Typography } from '@remarkable/ark-web';
import { useReduxCart } from '../../hooks/cart/useCart';

interface AffirmWidgetProps {
  amount: number;
  style?: React.CSSProperties;
}

export const AffirmWidget = (props: AffirmWidgetProps) => {
  const { amount } = props;

  const cart = useReduxCart();

  const country = useSelector((state: State) => state.countryDetector.country);

  const B2BThresholdMet = cart.helpers.getB2BThresholdMet();
  const affirmSupported = isAffirmSupportedCountry(country);
  const showAffirm = affirmSupported && !B2BThresholdMet;

  // NB: This should always be called after each component render to re-populate
  // the .affirm-as-low-as container below
  useEffect(() => {
    ThirdPartyScripts.loadAffirmScript();
    updateAffirmWidget();
  }, [amount, country]);

  if (!showAffirm) {
    return <div />;
  } else {
    return (
      <div
        style={{ ...props.style }}
        className="flex items-center justify-center mt-[15px] mx-0 mb-0 text-right [&>a]:text-grayscale-black [&_.affirm-modal-trigger]:underline"
      >
        {/* Affirm script automatically populates this <p/> container */}
        <Typography
          as="p"
          variant="interface-sm-book"
          className="affirm-as-low-as"
          data-page-type="payment"
          data-amount={amount}
        />
      </div>
    );
  }
};
