import { Info } from 'phosphor-react';
import React from 'react';

type Props = {
  content?: JSX.Element;
  heading: string;
};

export const InfoBox: React.FC<Props> = ({ content, heading }) => (
  <div className="p-16 mt-16 border border-solid rounded bg-opacity-10 border-grayscale-gray-400">
    <div className="flex items-center">
      <Info size={24} className="text-grayscale-gray-700" weight="fill" />
      {heading && <p className="pl-8 text-14 font-demi">{heading}</p>}
    </div>
    {content && <div className="mt-8 align-top text-14 text-dark">{content}</div>}
  </div>
);
