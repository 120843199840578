import { Button } from '@remarkable/ark-web';
import { Info } from 'phosphor-react';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';

interface CartConnectInfoProps {
  showModal: () => void;
  monthlyPrice: string;
}

export const CartConnectInfo = ({ showModal, monthlyPrice }: CartConnectInfoProps): JSX.Element => {
  return (
    <div className="w-full p-16 my-16 bg-light text-dark rounded-[4px]">
      <div className="align-center flex flex-row justify-between w-full">
        <div className="flex flex-col justify-center leading-125">
          <span className="text-16 font-bold self-center md:text-18">Satisfaction guarantee</span>
          <p className="text-12 sm:text-14">Try risk free for 100 days</p>
        </div>
        <Button
          variant="tertiary-neutral"
          onClick={() => {
            pushGAEventToDataLayer(
              eventCategoryEnum.PRODUCT_PAGE_INTERACTION,
              eventActionEnum.BUTTON,
              'Edit bundle from cart'
            );
            showModal();
          }}
          data-cy="edit-satisfaction-guarantee"
        >
          <Info />
        </Button>
      </div>

      <div className="h-[1px] bg-[#E0E0E0] my-16"></div>
      <div className="sm:text-14 text-12">
        <p className="flex flex-row justify-between font-bold">
          <span>Full refund within 100 days</span>
          <span>Included</span>
        </p>
        <p>Also includes accessories</p>

        <p className={`flex flex-row justify-between mt-12 font-bold `}>
          <span>1-year free Connect subscription</span>
          <span>Included</span>
        </p>
        <p>Then {monthlyPrice}/month. Cancel anytime.</p>
      </div>
    </div>
  );
};
