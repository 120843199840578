import { useContext, useMemo } from 'react';
import { GTMProductsDataContext } from 'src/contexts/GTMProductsDataContext';

export const useSkuPrices = (currency: string): { [sku: string]: number } => {
  const gtmProductsData = useContext(GTMProductsDataContext);
  if (gtmProductsData === null) {
    throw Error('useSkuPrices did not find any prices, something is wrong with data provider');
  }
  return useMemo(() => {
    // not sure if this merits a useMemo, but it is a technically (but not practically since there are not that many skus) expensive operation
    const skuPrices: { [sku: string]: number } = {}; // it surprisingly slow to reduce, so we prefer forEach... so much for functional JS :)
    for (const product of gtmProductsData.epProducts) {
      const amount = product.price.find((price) => price.currency === currency)?.amount;
      if (amount) {
        skuPrices[product.sku] = amount;
      }
    }
    return skuPrices;
  }, [currency, gtmProductsData.epProducts]);
};
