import { CheckCircle, Info } from 'phosphor-react';
import ExpandableModal from 'src/components/Modal/ExpandableModal';
import { useStaticConnectPrices } from 'src/views/MPF/hooks/useStaticConnectPrices';

import { Accordion, Typography } from '@remarkable/ark-web';
import classNames from 'classnames';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';

interface BulletPointProps {
  text: string;
}

const Bulletpoint = ({ text }: BulletPointProps) => {
  return (
    <span className={`flex flex-row items-center mb-8`}>
      <CheckCircle size={16} className="mr-8 text-brand-orange-400" />

      <Typography as="p" variant="responsive-body-sm" className="max-w-[80%]">
        {text}
      </Typography>
    </span>
  );
};

interface NumberedItemProps {
  text: JSX.Element;
  itemNumber: number;
}

const NumberedItem = ({ text, itemNumber }: NumberedItemProps): JSX.Element => {
  return (
    <div className="flex flex-row items-baseline">
      <div className="bg-light rounded-full w-32 h-32 min-w-[32px] flex items-center justify-center">
        <Typography variant="body-md-bold" as="p">
          {itemNumber + 1}
        </Typography>
      </div>
      <div className="ml-16 min-h-[32px] my-8">
        {itemNumber > 0 && (
          <div className="w-full mb-16 border-0 border-t border-dashed border-grayscale-gray-200"></div>
        )}
        {text}
      </div>
    </div>
  );
};

interface NumbersListProps {
  itemList: JSX.Element[];
}

const NumbersList = ({ itemList }: NumbersListProps) => {
  return (
    <div>
      {itemList.map((item, index) => (
        <NumberedItem text={item} itemNumber={index} key={index} />
      ))}
    </div>
  );
};

const numberedListText = [
  <Typography as="p" variant="responsive-body-sm">
    Register your return at{' '}
    <a target="_blank" href="https://returns.remarkable.com/">
      returns.remarkable.com
    </a>
  </Typography>,
  <Typography as="p" variant="responsive-body-sm">
    Pick a time and place our courier can collect your reMarkable and accessories. The return is pre-paid and free of
    charge.
  </Typography>,
  <Typography as="p" variant="responsive-body-sm">
    We‘ll issue a 100% refund as soon as we receive your device. The refund should appear in your account within 5-10
    business days.
  </Typography>,
];

const bulletPointTexts = [
  'Unlimited cloud storage',
  'reMarkable 2 Protection Plan',
  'Access to exclusive offers',
  'Mobile and desktop note-taking',
];

interface ConnectModalProps {
  showModal: boolean;
  handleClose: () => void;
  currency: string;
}

export const ConnectModal = ({ showModal, handleClose, currency }: ConnectModalProps): JSX.Element => {
  const connectPrices = useStaticConnectPrices(currency);
  const priceMonthly = connectPrices.connect.current;
  const breakpoint = useActiveBreakpoint();

  return (
    <ExpandableModal show={showModal} handleClose={() => handleClose()}>
      <div className="md:px-12">
        <Typography as="h2" variant="responsive-heading-sm" className="mt-16 mb-4 text-center">
          Satisfaction guarantee
        </Typography>
        <Typography as="p" variant="responsive-body-sm" className="mt-16 mb-16 text-center">
          Try reMarkable and a Connect subscription risk free. If you decide to return it, we offer free returns and a
          full refund.
        </Typography>
        <Accordion
          className={classNames('[&>Button>svg]:h-24 [&>Button>svg]:w-24', {
            '[&>div]:!pr-[0px] [&>div]:!mr-[14px]': breakpoint === 'xs',
          })}
          variant="secondary"
        >
          <Typography as="p" variant="responsive-body-sm" className="font-bold">
            Full refund within 100 days
          </Typography>
          <Typography as="p" variant="responsive-body-sm" className="pt-8 pb-16">
            Try reMarkable for 100 days and experience its incredible paper-like feel. If it’s not right for you, follow
            the steps below to return it.
          </Typography>
          <NumbersList itemList={numberedListText} />
        </Accordion>
        <Accordion
          className="[&>Button>svg]:h-24 [&>Button>svg]:w-24 border-none [&>div]:!pr-[0px] [&>div]:!mr-[14px]"
          variant="secondary"
          defaultOpen
        >
          <Typography as="p" variant="responsive-body-sm" className="font-bold">
            1-year free Connect trial
          </Typography>
          <Typography as="p" variant="responsive-body-sm" className="pt-8 pb-16">
            Enjoy seamless note-taking with unlimited cloud storage and sync, exclusive subscriber offers in our
            webshop, and 3 years of added protection for your device.
          </Typography>
          {bulletPointTexts.map((text, index) => (
            <Bulletpoint text={text} key={`bullet-${index}`} />
          ))}
          <div className="my-24 border-grayscale-gray-200 w-[calc(100%+0.9rem)] border-solid border-0 border-t" />
          <span className="flex flex-row items-start my-8">
            <Info weight="fill" className="shrink-0" size={18} />
            <Typography as="p" variant="responsive-body-sm" className="ml-8">
              Activate your free trial when setting up your device(s). After your included 1-year free subscription
              ends, Connect costs {priceMonthly}/mo. Cancel anytime.
            </Typography>
          </span>
        </Accordion>
      </div>
    </ExpandableModal>
  );
};
