export const doesScriptExist = (scriptSrc) => {
  return document.querySelectorAll(`script[src="${scriptSrc}"]`).length > 0;
};

export const loadHubspotScript = () => {
  const scriptSrc = '//js.hs-scripts.com/4478021.js';
  if (!doesScriptExist(scriptSrc)) {
    const scriptTag = document.createElement('script');
    scriptTag.src = scriptSrc;
    scriptTag.id = 'hs-script-loader';
    scriptTag.type = 'text/javascript';
    scriptTag.defer = true;
    if (document.body) {
      document.body.appendChild(scriptTag);
    }
  }
};

/* eslint-disable */
export const loadAffirmScript = () => {
  const scriptSrc = process.env.NEXT_PUBLIC_AFFIRM_CDN_URL;
  if (!window.affirm || !doesScriptExist(scriptSrc)) {
    const _affirm_config = {
      public_api_key: process.env.NEXT_PUBLIC_AFFIRM_PUBLIC_KEY,
      script: scriptSrc,
      session_id: 'YOUR_VISITOR_SESSION_ID',
    };
    (function (l, g, m, e, a, f, b) {
      let d,
        c = l[m] || {},
        h = document.createElement(f),
        n = document.getElementsByTagName(f)[0],
        k = function (a, b, c) {
          return function () {
            a[b]._.push([c, arguments]);
          };
        };
      c[e] = k(c, e, 'set');
      d = c[e];
      c[a] = {};
      c[a]._ = [];
      d._ = [];
      c[a][b] = k(c, a, b);
      a = 0;
      for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++) {
        d[b[a]] = k(c, e, b[a]);
      }
      a = 0;
      for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) {
        d[b[a]] = function () {};
      }
      h.async = !0;
      h.src = g[f];
      n.parentNode.insertBefore(h, n);
      delete g[f];
      d(g);
      l[m] = c;
    })(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready');
  }
};

export const disableReactDevTools = () => {
  const noop = () => undefined;
  const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

export const loadAutotrackScript = () => {
  const scriptSrc = '/autotrack.js';
  if (!doesScriptExist(scriptSrc)) {
    const scriptTag = document.createElement('script');
    scriptTag.src = scriptSrc;
    scriptTag.defer = true;
    if (document.body) {
      document.body.appendChild(scriptTag);
    }
  }
};
